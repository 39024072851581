/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

const $ = require("jquery")

exports.onRouteUpdate = () => {
  $(document).ready(function () {
    /* === Mobile Menu starts === */
    $(function () {
      $(".acc__title").click(function (j) {
        var dropDown = $(this).closest(".acc__card").find(".acc__panel")
        $(this).closest(".acc").find(".acc__panel").not(dropDown).slideUp()

        if ($(this).hasClass("active")) {
          $(this).removeClass("active")
        } else {
          $(this)
            .closest(".acc")
            .find(".acc__title.active")
            .removeClass("active")
          $(this).addClass("active")
        }

        dropDown.stop(false, true).slideToggle()
        j.preventDefault()
      })
    })
    /* === Mobile Menu ends === */
  })
}

// gtm code starts
/* GTM CODES STARTS */

//default id for english site
let setID = `KDT5ZH8`

const setGtm = () => {
  insertJS()
}

const addgtmLink = `https://www.googletagmanager.com/gtm.js?id=GTM-${setID}`

//add gtm
const insertJS = () => {
  const addIframe = src => {
    const s = document.createElement(`noscript`)
    const iframe = document.createElement(`iframe`)
    iframe.src = src
    iframe.height = "0"
    iframe.style = "visiblity:hidden"
    document.getElementsByTagName(`body`)[0].appendChild(s)
      .append(`<iframe  src="https://www.googletagmanager.com/ns.html?id=GTM-${setID}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`)
  }
  const insertF = () => {
    const s = document.createElement(`script`)
    const src = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-${setID}');`
    document.getElementsByTagName(`head`)[0].appendChild(s).append(src)
  }
  addIframe(addgtmLink)
  insertF()
}

exports.onClientEntry = () => {
  setGtm()
}
// gtm code ends
